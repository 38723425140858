<template>
  <b-row>
    <b-col sm="12">
      <b-card header-tag="header" footer-tag="footer">
        <b-row>
          <b-col>
            <h4 id="traffic" class="card-title mb-0">Instancias</h4>
            <div class="small text-muted">Administración general de todas las instancias</div>
          </b-col>
        </b-row>
      </b-card>

      <b-row>
        <b-col lg="9">
          <b-row>
            <b-col md="9">                         
              <b-row>
                <b-col md="5">                         
                  <Widget :configWidget="configWidget" reference="cant_servers" :hasAccess="configWidget.elements.cantServers" />
                </b-col>
                <b-col md="7">                         
                  <Widget :configWidget="configWidget" reference="list_server_problem_resources" :hasAccess="configWidget.elements.listServerProblemResources" />
                </b-col>
                <b-col md="12">                         
                  <Widget :configWidget="configWidget" reference="list_server_problem" :hasAccess="configWidget.elements.listServerProblem" />
                </b-col>    
                <b-col md="4">
                  <Widget :configWidget="configWidget" reference="list_system_not_access" :hasAccess="configWidget.elements.listSystemNotAccess" />
                  <Widget :configWidget="configWidget" reference="list_system_not_access_others" :hasAccess="configWidget.elements.listSystemNotAccessOthers" />                   
                </b-col>
                <b-col md="8">                  
                  <b-row>
                    <b-col md="6">
                      <Widget :configWidget="configWidget" reference="list_system_dns_type_aaaa" :hasAccess="configWidget.elements.listSystemDnsTypeAAAA" />                  
                    </b-col>      
                    <b-col md="6">
                      <Widget :configWidget="configWidget" reference="list_system_dns_error_ip" :hasAccess="configWidget.elements.listSystemDnsErrorIp" />                  
                    </b-col>                                
                    <b-col md="12" v-if="parameters.integrateServices">
                      <Widget :configWidget="configWidget" reference="list_instances_without_services" :hasAccess="configWidget.elements.listInstancesWithoutServices" />
                    </b-col>                    
                  </b-row>                  
                </b-col>
              </b-row>
            </b-col>
            <b-col md="3">                         
              <b-row>
                <b-col md="12">                         
                  <Widget :configWidget="configWidget" reference="list_system_unlocked" :hasAccess="configWidget.elements.listSystemUnlocked" />
                  <Widget :configWidget="configWidget" reference="list_system_not_audit" :hasAccess="configWidget.elements.listSystemNotAudit" />
                  <Widget :configWidget="configWidget" reference="list_system_not_backups" :hasAccess="configWidget.elements.listSystemNotBackups" />
                  <Widget :configWidget="configWidget" reference="list_system_not_backups_others" :hasAccess="configWidget.elements.listSystemNotBackupsOthers" />                  
                </b-col>                  
              </b-row>
            </b-col>
          </b-row>
        </b-col>  
        <b-col lg="3">        
          
          <b-list-group>
            <b-list-group-item href="#" class="flex-column align-items-start" @click="openInstancesCategories()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Agrupación</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Agrupación de servidores
              </p>
            </b-list-group-item>

            <b-list-group-item href="#" class="flex-column align-items-start" @click="openInstancesServer()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Servidores</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de servidores
              </p>
            </b-list-group-item>

            <b-list-group-item href="#" class="flex-column align-items-start mt-3" @click="openInstancesSystem()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Gestionalo</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de instancias de Gestionalo
              </p>
            </b-list-group-item>    

            <b-list-group-item href="#" class="flex-column align-items-start" @click="openInstancesSystemOthers()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Laravel</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de sistemas de Laravel (PHP)
              </p>
            </b-list-group-item>     

            <b-list-group-item href="#" class="flex-column align-items-start" @click="openInstancesSystemDocker()">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">Docker</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                Administración de sistemas con Docker
              </p>
            </b-list-group-item>                                 
          </b-list-group>

          <hr v-if="arr.PHPCustom.length">
          <b-list-group class="mt-2" v-if="arr.PHPCustom.length">
            <b-list-group-item v-for="item in arr.PHPCustom" :key="item.id" href="#" class="flex-column align-items-start" @click="openPHPCustom(item)">
              <div class="d-flex w-100 justify-content-between">
                <h5 class="mb-1">{{item.name}}</h5>
                <small>
                  <b-icon icon="chevron-double-right" class="h5"></b-icon>
                </small>
              </div>

              <p class="mb-1">
                {{item.observations}}
              </p>
            </b-list-group-item>
          </b-list-group>          
        </b-col>        
      </b-row>

    </b-col>
  </b-row>
</template>
<script>
  import serviceAPI from './services'
  import Error from '@/handler/error'
  import Session from '@/handler/session'
  import Profiles from '@/config/profiles'
  import Modules from '@/config/modules'
  import Helper from '@/handler/helper'
  import Widget from '@/components/inc/widget/widget'
  import Param from '@/config/parameters'
  import ServicesPHP from '@/components/modules/users/php/staff/services'

  export default {
    components: {
      Widget
    },
    data: () => {
      return {
        access: {
          module_id: Modules.INSTANCIAS,
          profile_id: Profiles.PERSONAL,
          view_reference: 'dashboard',
          elements: {}
        },
        parameters: {
          integrateServices: Helper.hasParametersAccess(Param.P59),          
        },            
        configWidget: {
          module_id: Modules.INSTANCIAS,
          profile_id: Profiles.PERSONAL,
          elements: {             
            cantServers: true,
            listServerProblemResources: true,
            listServerProblem: true,
            listSystemNotAudit: true,
            listSystemUnlocked: true,
            listSystemNotBackups: true,
            listSystemNotBackupsOthers: true,
            listSystemNotAccess: true,
            listSystemNotAccessOthers: true,    
            listSystemDnsTypeAAAA: true,
            listSystemDnsErrorIp: true,
            listInstancesWithoutServices: true,
          }
        },
        primaryColor: '',
        arr: {
          PHPCustom: []
        }        
      }
    },
    created () {
      /* Configurar permisos de vistas y elementos */
      Helper.hasAccessView(this.access)
      /* Fin configuracion */

      /* Configurar permisos de los widgets */      
      this.configWidget.elements.cantServers = Helper.hasAccessWidget(this.configWidget, 'cant_servers')      
      this.configWidget.elements.listServerProblemResources = Helper.hasAccessWidget(this.configWidget, 'list_server_problem_resources')      
      this.configWidget.elements.listServerProblem = Helper.hasAccessWidget(this.configWidget, 'list_server_problem')      
      this.configWidget.elements.listSystemNotAudit = Helper.hasAccessWidget(this.configWidget, 'list_system_not_audit')      
      this.configWidget.elements.listSystemUnlocked = Helper.hasAccessWidget(this.configWidget, 'list_system_unlocked')      
      this.configWidget.elements.listSystemNotBackups = Helper.hasAccessWidget(this.configWidget, 'list_system_not_backups')      
      this.configWidget.elements.listSystemNotBackupsOthers = Helper.hasAccessWidget(this.configWidget, 'list_system_not_backups_others')    
      this.configWidget.elements.listSystemNotAccess = Helper.hasAccessWidget(this.configWidget, 'list_system_not_access')      
      this.configWidget.elements.listSystemNotAccessOthers = Helper.hasAccessWidget(this.configWidget, 'list_system_not_access_others')            
      this.configWidget.elements.listSystemDnsTypeAAAA = Helper.hasAccessWidget(this.configWidget, 'list_system_dns_type_aaaa')   
      this.configWidget.elements.listSystemDnsErrorIp = Helper.hasAccessWidget(this.configWidget, 'list_system_dns_error_ip') 
      this.configWidget.elements.listInstancesWithoutServices = Helper.hasAccessWidget(this.configWidget, 'list_instances_without_services')       
      /* Fin configuracion */
    },
    mounted() {
      this.setup()
      this.getPHPCustom()
    }, 
    methods: {
      setup () {        
        if(Session.getSession().settings) {    
          this.primaryColor = Session.getSession().settings.color_primary
                    
          this.createCSS()
        }
      },         
      createCSS() {
        var hoja = document.createElement('style')
        hoja.innerHTML = ""      
        hoja.innerHTML = hoja.innerHTML  + ".list-group-item-action:hover, .list-group-item-action:focus { background-color: " + this.primaryColor + "; }"

        document.body.appendChild(hoja);
      }, 
      
      openInstancesCategories() {
        this.$router.push({ name: 'InstancesStaffCrudInstancesCategories' })
      },        
      openInstancesServer() {
        this.$router.push({ name: 'InstancesStaffCrudInstancesServer' })
      },
      openInstancesSystem() {
        this.$router.push({ name: 'InstancesStaffCrudInstancesSystem' })
      }, 
      openInstancesSystemOthers() {
        this.$router.push({ name: 'InstancesStaffCrudInstancesSystemOthers' })
      }, 
      openInstancesSystemDocker() {
        this.$router.push({ name: 'InstancesStaffCrudInstancesSystemDocker' })
      },

      getPHPCustom() {
        ServicesPHP.obtenerPHPByModule(Modules.INSTANCIAS)
        .then((response) => {
          this.arr.PHPCustom = response.data       
        })
      },
      openPHPCustom(item) {
        this.$router.push({ name: 'ViewStaffViewPHPCustom', params: {id: item.id} })
      }      
    }    
   
  }
</script>

<style>

</style>
